"use strict";

import { CountUp } from 'countup.js';

const el1 = document.querySelector('.stats__num1');
const el2 = document.querySelector('.stats__num2');
const el3 = document.querySelector('.stats__num3');

const countUp1 = new CountUp(el1, 19);
const countUp2 = new CountUp(el2, 23);
const countUp3 = new CountUp(el3, 38);

const observer = new window.IntersectionObserver(([entry]) => {
  if (entry.isIntersecting) {
    countUp1.start();
    countUp2.start();
    countUp3.start();
  }
}, {
  root: null,
  threshold: 1,
})

if (el1) {
  observer.observe(el1);
}

if (el2) {
  observer.observe(el2);
}

if (el3) {
  observer.observe(el3);
}