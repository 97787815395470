"use strict";

// Handle Mobile Burger Click
const hamburger = () => {
  const burger = document.querySelector(".hamburger");
  const navi = document.querySelector("nav");

  if (burger) {
    burger.addEventListener("click", () => {
      burger.classList.toggle("is-active");
      navi.classList.toggle("is-active");
    });
  }
};

// Menu Click
const menuClick = () => {
  const navLinks = document.querySelectorAll('nav a');
  const burger = document.querySelector(".hamburger");
  const navi = document.querySelector('nav');

  if (navLinks.length) {
    navLinks.forEach(link => {
      link.addEventListener("click", () => {
        navi.classList.remove("is-active");
        burger.classList.remove("is-active");
      });
    });
  }
}

// Initialize
hamburger();
menuClick();