"use strict";

// Scroll to id
const links = document.querySelectorAll('.site-link');

if (links) {
  links.forEach((link) => {
    link.addEventListener('click', clickHandler);
  });

  function clickHandler(e) {
    e.preventDefault();
    const href = this.getAttribute('href');
    const offsetTop = document.querySelector(href).offsetTop - 80;

    scroll({
      top: offsetTop,
      behavior: 'smooth'
    });
  }
}

// Scroll to ID on Pageload
window.onload = () => {
  if (window.location.hash) {
    const hash = window.location.hash;
    const scrollToDiv = document.querySelector('#keyfigures').offsetTop - 80;

    if (hash.length) {
      scroll({
        top: scrollToDiv,
        behavior: 'smooth'
      });
    }
  }
}

// To Top Button
const button = document.querySelector('.scrolltop-button');

if (button) {
  button.addEventListener('click', () => {
    topFunction();
  });

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
}