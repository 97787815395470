[
  {
    "id": 1,
    "list": [
      {
        "page": 1,
        "image": "assets/thumbnails/01.jpg",
        "pagetext": "I"
      },
      {
        "page": null,
        "pagetext": ""
      }
    ]
  },
  {
    "id": 2,
    "list": [
      {
        "page": 2,
        "image": "assets/thumbnails/02.jpg",
        "pagetext": "II"
      },
      {
        "page": 3,
        "pagetext": "III"
      }
    ]
  },
  {
    "id": 3,
    "list": [
      {
        "page": 4,
        "image": "assets/thumbnails/03.jpg",
        "pagetext": "IV"
      },
      {
        "page": 5,
        "pagetext": "1"
      }
    ]
  },
  {
    "id": 4,
    "list": [
      {
        "page": 6,
        "image": "assets/thumbnails/04.jpg",
        "pagetext": "2"
      },
      {
        "page": 7,
        "pagetext": "3"
      }
    ]
  },
  {
    "id": 5,
    "list": [
      {
        "page": 8,
        "image": "assets/thumbnails/05.jpg",
        "pagetext": "4"
      },
      {
        "page": 9,
        "pagetext": "5"
      }
    ]
  },
  {
    "id": 6,
    "list": [
      {
        "page": 10,
        "image": "assets/thumbnails/06.jpg",
        "pagetext": "6"
      },
      {
        "page": 11,
        "pagetext": "7"
      }
    ]
  },
  {
    "id": 7,
    "list": [
      {
        "page": 12,
        "image": "assets/thumbnails/07.jpg",
        "pagetext": "8"
      },
      {
        "page": 13,
        "pagetext": "9"
      }
    ]
  },
  {
    "id": 8,
    "list": [
      {
        "page": 14,
        "image": "assets/thumbnails/08.jpg",
        "pagetext": "10"
      },
      {
        "page": 15,
        "pagetext": "11"
      }
    ]
  },
  {
    "id": 9,
    "list": [
      {
        "page": 16,
        "image": "assets/thumbnails/09.jpg",
        "pagetext": "12"
      },
      {
        "page": 17,
        "pagetext": "13"
      }
    ]
  },
  {
    "id": 10,
    "list": [
      {
        "page": 18,
        "image": "assets/thumbnails/10.jpg",
        "pagetext": "14"
      },
      {
        "page": 19,
        "pagetext": "15"
      }
    ]
  },
  {
    "id": 11,
    "list": [
      {
        "page": 20,
        "image": "assets/thumbnails/11.jpg",
        "pagetext": "16"
      },
      {
        "page": 21,
        "pagetext": "17"
      }
    ]
  },
  {
    "id": 12,
    "list": [
      {
        "page": 22,
        "image": "assets/thumbnails/12.jpg",
        "pagetext": "18"
      },
      {
        "page": 23,
        "pagetext": "19"
      }
    ]
  },
  {
    "id": 13,
    "list": [
      {
        "page": 24,
        "image": "assets/thumbnails/13.jpg",
        "pagetext": "20"
      },
      {
        "page": 25,
        "pagetext": "21"
      }
    ]
  },
  {
    "id": 14,
    "list": [
      {
        "page": 26,
        "image": "assets/thumbnails/14.jpg",
        "pagetext": "22"
      },
      {
        "page": 27,
        "pagetext": "23"
      }
    ]
  },
  {
    "id": 15,
    "list": [
      {
        "page": 28,
        "image": "assets/thumbnails/15.jpg",
        "pagetext": "24"
      },
      {
        "page": 29,
        "pagetext": "25"
      }
    ]
  },
  {
    "id": 16,
    "list": [
      {
        "page": 30,
        "image": "assets/thumbnails/16.jpg",
        "pagetext": "26"
      },
      {
        "page": 31,
        "pagetext": "27"
      }
    ]
  },
  {
    "id": 17,
    "list": [
      {
        "page": 32,
        "image": "assets/thumbnails/17.jpg",
        "pagetext": "28"
      },
      {
        "page": 33,
        "pagetext": "29"
      }
    ]
  },
  {
    "id": 18,
    "list": [
      {
        "page": 34,
        "image": "assets/thumbnails/18.jpg",
        "pagetext": "30"
      },
      {
        "page": 35,
        "pagetext": "31"
      }
    ]
  },
  {
    "id": 19,
    "list": [
      {
        "page": 36,
        "image": "assets/thumbnails/19.jpg",
        "pagetext": "32"
      },
      {
        "page": 37,
        "pagetext": "33"
      }
    ]
  },
  {
    "id": 20,
    "list": [
      {
        "page": 38,
        "image": "assets/thumbnails/20.jpg",
        "pagetext": "34"
      },
      {
        "page": 39,
        "pagetext": "35"
      }
    ]
  },
  {
    "id": 21,
    "list": [
      {
        "page": 40,
        "image": "assets/thumbnails/21.jpg",
        "pagetext": "36"
      },
      {
        "page": 41,
        "pagetext": "37"
      }
    ]
  },
  {
    "id": 22,
    "list": [
      {
        "page": 42,
        "image": "assets/thumbnails/22.jpg",
        "pagetext": "38"
      },
      {
        "page": 43,
        "pagetext": "39"
      }
    ]
  },
  {
    "id": 23,
    "list": [
      {
        "page": 44,
        "image": "assets/thumbnails/23.jpg",
        "pagetext": "40"
      },
      {
        "page": 45,
        "pagetext": "41"
      }
    ]
  },
  {
    "id": 24,
    "list": [
      {
        "page": 46,
        "image": "assets/thumbnails/24.jpg",
        "pagetext": "42"
      },
      {
        "page": 47,
        "pagetext": "43"
      }
    ]
  },
  {
    "id": 25,
    "list": [
      {
        "page": 48,
        "image": "assets/thumbnails/25.jpg",
        "pagetext": "44"
      },
      {
        "page": 49,
        "pagetext": "45"
      }
    ]
  },
  {
    "id": 26,
    "list": [
      {
        "page": 50,
        "image": "assets/thumbnails/26.jpg",
        "pagetext": "46"
      },
      {
        "page": 51,
        "pagetext": "47"
      }
    ]
  },
  {
    "id": 27,
    "list": [
      {
        "page": 52,
        "image": "assets/thumbnails/27.jpg",
        "pagetext": "48"
      },
      {
        "page": 53,
        "pagetext": "49"
      }
    ]
  },
  {
    "id": 28,
    "list": [
      {
        "page": 54,
        "image": "assets/thumbnails/28.jpg",
        "pagetext": "50"
      },
      {
        "page": 55,
        "pagetext": "51"
      }
    ]
  },
  {
    "id": 29,
    "list": [
      {
        "page": 56,
        "image": "assets/thumbnails/29.jpg",
        "pagetext": "51"
      },
      {
        "page": 57,
        "pagetext": "51"
      }
    ]
  },
  {
    "id": 30,
    "list": [
      {
        "page": 58,
        "image": "assets/thumbnails/30.jpg",
        "pagetext": "52"
      },
      {
        "page": 59,
        "pagetext": "53"
      }
    ]
  },
  {
    "id": 31,
    "list": [
      {
        "page": 60,
        "image": "assets/thumbnails/31.jpg",
        "pagetext": "54"
      },
      {
        "page": 61,
        "pagetext": "55"
      }
    ]
  },
  {
    "id": 32,
    "list": [
      {
        "page": 62,
        "image": "assets/thumbnails/32.jpg",
        "pagetext": "56"
      },
      {
        "page": 63,
        "pagetext": "57"
      }
    ]
  },
  {
    "id": 33,
    "list": [
      {
        "page": 64,
        "image": "assets/thumbnails/33.jpg",
        "pagetext": "58"
      },
      {
        "page": 65,
        "pagetext": "59"
      }
    ]
  },
  {
    "id": 34,
    "list": [
      {
        "page": 66,
        "image": "assets/thumbnails/34.jpg",
        "pagetext": "60"
      },
      {
        "page": 67,
        "pagetext": "61"
      }
    ]
  },
  {
    "id": 35,
    "list": [
      {
        "page": 68,
        "image": "assets/thumbnails/35.jpg",
        "pagetext": "62"
      },
      {
        "page": 69,
        "pagetext": "63"
      }
    ]
  },
  {
    "id": 36,
    "list": [
      {
        "page": 70,
        "image": "assets/thumbnails/36.jpg",
        "pagetext": "64"
      },
      {
        "page": 71,
        "pagetext": "65"
      }
    ]
  },
  {
    "id": 37,
    "list": [
      {
        "page": 72,
        "image": "assets/thumbnails/37.jpg",
        "pagetext": "66"
      },
      {
        "page": 73,
        "pagetext": "67"
      }
    ]
  },
  {
    "id": 38,
    "list": [
      {
        "page": 74,
        "image": "assets/thumbnails/38.jpg",
        "pagetext": "V"
      },
      {
        "page": 75,
        "pagetext": "VI"
      }
    ]
  },
  {
    "id": 39,
    "list": [
      {
        "page": 76,
        "image": "assets/thumbnails/39.jpg",
        "pagetext": "VII"
      },
      {
        "page": 77,
        "pagetext": "VIII"
      }
    ]
  },
  {
    "id": 40,
    "list": [
      {
        "page": 78,
        "image": "assets/thumbnails/40.jpg",
        "pagetext": "IX"
      },
      {
        "page": 79,
        "pagetext": ""
      }
    ]
  }
]