import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf.min";
import * as pdfjsViewer from 'pdfjs-dist/legacy/web/pdf_viewer.js';
import * as pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import thumbLink from './pdfData/thumbLinks.json';

export class PDFViewer {
  constructor(element) {
    this.modalItem = element
    this.handleThumbsCloseButton();
    this.handleInsightsLink();
    this.renderThumbsList();
    this.handleThumbs();
    this.renderModal();
  }

  handleInsightsLink() {
    const insightsLink = document.querySelectorAll(".insights-magazine-link");
    const modal = document.querySelector(".pdf-viewer__js-modal");

    if (insightsLink.length) {
      insightsLink.forEach(link => {
        link.addEventListener("click", () => {
          modal.classList.add("pdf-viewer-insights-link")
        });
      });
    }
  }

  renderThumbsList() {
    const thumbsContainer = document.querySelector(".pdf-viewer__thumbs ul");
    let list = '';

    thumbLink.forEach(item => {
      list += `
        <li>
          <div class="pdf-viewer__thumbs-wrapper">
            <a href="QIAGEN-insights-magazine-2022.pdf" data-pdf-file="QIAGEN-insights-magazine-2022.pdf?page=${item.id}" class="pdf-link">
              <div class="pdf-viewer__thumbs-wrapper-inner">
                <div class="pdf-viewer__thumbs-left-page">
                  <img src=${item.list[0].image} alt="">
                </div>
              </div>
            </a>
            ${item.list[1].pagetext === "" ? (
              `<span>${item.list[0].pagetext}</span>`
            ) : (
              `<span>${item.list[0].pagetext} - ${item.list[1].pagetext}</span>`
            )}
          </div>
        </li>
      `
    });

    if (thumbsContainer) {
      thumbsContainer.innerHTML = list
    }
  }

  handleThumbs() {
    const thumbsLink = document.querySelector(".pdf-viewer__thumbs-link");
    const thumbsWrapper = document.querySelector(".pdf-viewer__thumbs");
    const thumbsLinks = document.querySelectorAll(".pdf-viewer__thumbs a");

    if (thumbsLinks.length) {
      thumbsLinks.forEach(link => {
        link.addEventListener("click", (e) => {
          e.preventDefault();

          if (thumbsWrapper.classList.contains("pdf-viewer__thumbs-active")) {
            thumbsWrapper.classList.remove("pdf-viewer__thumbs-active")
          }
        });
      })
    }

    if (thumbsLink) {
      thumbsLink.addEventListener("click", () => {
        thumbsWrapper.classList.toggle("pdf-viewer__thumbs-active");
      })
    }
  }

  handleThumbsClose() {
    const thumbsWrapper = document.querySelector(".pdf-viewer__thumbs");
    
    if (thumbsWrapper) {
      if (thumbsWrapper.classList.contains("pdf-viewer__thumbs-active")) {
        thumbsWrapper.classList.remove("pdf-viewer__thumbs-active")
      }
    }
  }

  handleThumbsCloseButton() {
    const thumbsWrapper = document.querySelector(".pdf-viewer__thumbs");
    const thumbsClose = document.querySelector(".pdf-viewer__thumbs-close");

    if (thumbsClose) {
      thumbsClose.addEventListener("click", () => {
        console.log("asdsdf");
        thumbsWrapper.classList.remove("pdf-viewer__thumbs-active")
      });
    }
  }

  resizeFullscreen(downloadLink, body) {
    const pdfLinkPage = downloadLink.getAttribute('data-page-link');
    const pageLinkPageNumber = pdfLinkPage.split("=")[1];

    const isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;

    if (isFullScreen) {
      body.classList.remove("pdf-viewer__fullscreen-mode-none");
      body.classList.add("pdf-viewer__fullscreen-mode");

      this.initPDFViewer(pdfLinkPage, pageLinkPageNumber, "page-fit");
    } else {
      body.classList.remove("pdf-viewer__fullscreen-mode");
      body.classList.add("pdf-viewer__fullscreen-mode-none");
      
      this.initPDFViewer(pdfLinkPage, pageLinkPageNumber, "page-fit");
    }
  }
  
  shareLinks(pdfLink = "") {
    const socialLinksList = document.querySelectorAll(".pdf-viewer__socials a");

    const linkedIn = "https://www.linkedin.com/shareArticle?mini=true&url=";
    const facebook = "https://www.facebook.com/sharer/sharer.php?u=";
    const twitter  = "https://twitter.com/intent/tweet?text=";

    if (socialLinksList.length) {
      socialLinksList.forEach((sociaLink, index) => {
        if (index === 0) {
          sociaLink.href = linkedIn + window.location + pdfLink;
        } else if (index === 1) {
          sociaLink.href = facebook + window.location + pdfLink;
        } else if (index === 2) {
          sociaLink.href = twitter + window.location + pdfLink;
        }
      });
    }
  }

  handleLoading() {
    const pdfLoading = document.querySelector(".pdf-viewer__loading");
    const pdfViewerClass = document.querySelector("#viewer");

    if (pdfViewerClass) {
      setTimeout(() => {
        if (pdfViewerClass.childNodes.length > 0) {
          pdfLoading.classList.add("pdf-viewer__loading-hide");
        }
      }, 100)
    }
  }

  renderModal() {
    const pdfLink = document.querySelectorAll(".pdf-link");
    const modal = document.querySelector(".pdf-viewer__js-modal");
    const closeModal = document.querySelector(".pdf-viewer__close");
    const downloadLink = document.querySelector(".pdf-viewer__download");
    const socials = document.querySelector(".pdf-viewer__socials");
    const socialClose = document.querySelector(".pdf-viewer__action-close");
    const socialShare = document.querySelector(".pdf-viewer__action-share");
    const socialsShare = document.querySelector(".pdf-viewer__share");

    // Search all PDF links and init PDF Viewer on click
    if (pdfLink.length) {
      pdfLink.forEach(link => {
        const pdfLink = link.getAttribute('data-pdf-file');

        link.addEventListener("click", (e) => {
          if (/Mobi|Android/i.test(navigator.userAgent)) {
            null
          } else {
            e.preventDefault();
            modal.classList.add("pdf-viewer__modal-active");
            downloadLink.setAttribute("data-page-link", link.getAttribute('data-pdf-file'));
            downloadLink.href = link.getAttribute('data-pdf-file').split("?")[0];
            downloadLink.title = `Download ${link.getAttribute('data-pdf-file')}`;
  
            this.handleLoading();
            this.initPDFViewer(pdfLink, "", "page-fit");
          }
        });
        
        this.shareLinks(pdfLink);
      });
    }

    // Close Modal
    if (closeModal) {
      closeModal.addEventListener("click", () => {
        modal.classList.remove("pdf-viewer__modal-active");
        modal.classList.remove("pdf-viewer-insights-link");
        this.handleThumbsClose()
      })
    }

    // Social Sharing Buttons
    if (socialsShare) {
      socialsShare.addEventListener("click", () => {
        socials.classList.toggle("pdf-viewer__socials-active");
        socialShare.classList.toggle("pdf-viewer__action-share-active");
        socialClose.classList.toggle("pdf-viewer__action-close-active")
      });
    }

    // Fullscreen API
    const fullscreen = document.querySelector(".pdf-viewer__fullscreen");
    const body = document.querySelector("body");
    const viewContainer = document.getElementById("viewerContainer");

    if (body) {
      body.classList.add("pdf-viewer__fullscreen-mode-none");
    }

    if (fullscreen) {
      fullscreen.addEventListener("click", () => {
        const pdfLinkPage = downloadLink.getAttribute('data-page-link');
        const pageLinkPageNumber = pdfLinkPage.split("=")[1];

        if (viewContainer.requestFullscreen) {
          viewContainer.requestFullscreen();
          this.initPDFViewer(pdfLinkPage, pageLinkPageNumber, "page-fit");
        } else if (viewContainer.webkitRequestFullscreen) {
          viewContainer.webkitRequestFullscreen();
          this.initPDFViewer(pdfLinkPage, pageLinkPageNumber, "page-fit");
        } else if (viewContainer.msRequestFullscreen) {
          viewContainer.msRequestFullscreen();
          this.initPDFViewer(pdfLinkPage, pageLinkPageNumber, "page-fit");
        }

        this.handleThumbsClose()
      });
    }

    if (viewContainer) {
      viewContainer.addEventListener("fullscreenchange", () => {
        this.resizeFullscreen(downloadLink, body)
      })
      viewContainer.addEventListener("webkitfullscreenchange", () => {
        this.resizeFullscreen(downloadLink, body)
      })
      viewContainer.addEventListener("mozfullscreenchange", () => {
        this.resizeFullscreen(downloadLink, body)
      })
      viewContainer.addEventListener("msfullscreenchange", () => {
        this.resizeFullscreen(downloadLink, body)
      })
    }
  }
  
  // Init PDF Viewer (pdfLink)
  initPDFViewer(pdfLink, pageNumber, scale, spreadMode = 0) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    
    const pdfUrl = pdfLink;
    const searchPdf = "";
    const container = document.getElementById("viewerContainer");
    container.style.position = "absolute";
    const eventBus = new pdfjsViewer.EventBus();
    
    // (Optionally) enable hyperlinks within PDF files.
    const pdfLinkService = new pdfjsViewer.PDFLinkService({
      eventBus,
    });
    
    // (Optionally) enable find controller.
    const pdfFindController = new pdfjsViewer.PDFFindController({
      eventBus,
      linkService: pdfLinkService,
    });
    
    const pdfViewer = new pdfjsViewer.PDFViewer({
      container,
      eventBus,
      linkService: pdfLinkService,
      findController: pdfFindController,
    });
    
    pdfLinkService.setViewer(pdfViewer);
    
    eventBus.on("pagesinit", function () {
      // We can use pdfViewer now, e.g. let's change default scale.
      pdfViewer.currentScaleValue = scale;
      pdfViewer.spreadMode = spreadMode;

      const fullscreen = document.querySelector(".pdf-viewer__fullscreen");

      if (fullscreen) {
        fullscreen.addEventListener("click", () => {
          pdfViewer._resetView();
        });
      }

      // Check for internal or external link
      const params = new URLSearchParams(window.location.search)
      const pdfPage = params.get('page')

      if (pdfPage) {
        pageNumber = pdfPage
      } else {
        pageNumber = pdfLink.split("=")[1];
      }

      if (isNaN(parseInt(pageNumber))) {
        null
      } else {
        pdfViewer.scrollPageIntoView({ pageNumber: parseInt(pageNumber) });
      }
    
      // We can try searching for things.
      if (searchPdf) {
        eventBus.dispatch("find", { type: "", query: searchPdf });
      }
    });

    // Loading document.
    const loadingTask = pdfjsLib.getDocument({
      url: pdfUrl,
    });

    (async function () {
      const pdfDocument = await loadingTask.promise;
      pdfViewer.setDocument(pdfDocument);
      pdfLinkService.setDocument(pdfDocument, null);
    })();
  }
}

// ===== INIT ===== //
const modals = document.querySelectorAll('.pdf-viewer__js-modal');

if (modals.length) {
  modals.forEach(modal => {
    new PDFViewer(modal);
  })
}