"use strict";

import { CountUp } from 'countup.js';

const el4 = document.querySelector('.stats__num4');
const el5 = document.querySelector('.stats__num5');
const el6 = document.querySelector('.stats__num6');

const countUp4 = new CountUp(el4, 34);
const countUp5 = new CountUp(el5, 22);
const countUp6 = new CountUp(el6, 500);

// Observer
const observer = new window.IntersectionObserver(([entry]) => {
  if (entry.isIntersecting) {
    countUp4.start();
    countUp5.start();
    countUp6.start();
  }
}, {
  root: null,
  threshold: 1,
})

// Handle Facts Click
const handleFacts = () => {
  const statsButton = document.querySelector(".stats__btn");
  const statsWrap = document.querySelectorAll(".stats__more");

  if (statsButton) {
    statsButton.addEventListener("click", (e) => {
      e.preventDefault();

      observer.observe(el4);
      observer.observe(el5);
      observer.observe(el6);

      statsWrap.forEach(stat => {
        stat.classList.toggle("stats__more-active");
      });
      
      statsButton.classList.toggle("stats__btn-show-active");
    });
  }
};

// Initialize
handleFacts();