"use strict";

import AOS from 'aos';

import './nav';
import './downloads';
import './facts';
import './countup';
import './toTop';
import './pdf-viewer';

AOS.init();

