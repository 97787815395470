"use strict";

// Handle Downloads Click
const handleDownloads = () => {
  const downloadButton = document.querySelector(".downloads__btn");
  const downloadWrap = document.querySelectorAll(".downloads__wrap");

  if (downloadButton) {
    downloadButton.addEventListener("click", (e) => {
      e.preventDefault();

      downloadWrap.forEach(download => {
        download.classList.toggle("downloads__wrap-active");
      });

      downloadButton.classList.toggle("downloads__btn-show-active");
    });
  }
};

// Initialize
handleDownloads();